@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500;700&family=PT+Sans+Caption&display=swap');

#root {
  height: 100%;
  background: #f2f2f2;
  font-family: 'PT Sans Caption', sans-serif;
}

html.js-focus-visible {
  background: #f2f2f2;
}

body {
  color: $favColor;
  background: #f2f2f2;
}

.fa-hamburger {
  color: $favColor;
}

.fa-hamburger:hover {
  color: #777777;
}

#close {
  font-size: x-large;
}

.userNameContainer {
  color: $favColor
}

.userName {
  font-weight: bold;
}

div.secret {
  display: flex;
  flex-wrap: wrap;
  width: auto;
  height: auto;
}

div#userCard.card {
  height: 100px;
  width: 200px;
  border: none;
  display: flex;
  align-items: center;
}

.transition-fade {
  transition: 0.4s;
  opacity: 1;
}

// Navbar
nav.navbar.navbar-expand-lg.fixed-top {
  background-color: #0a0a0a;
}

a.navbar-brand {
  color: $favColor;
  font-size: 1.8rem;
}

a.navbar-brand:hover {
  color: #777777;
}

a.nav-link {
  color: $favColor;
  font-size: 1.4rem;
}

a.nav-link:hover {
  color: #777777;
}

#infoBtn {
  border: 1px solid $favColor;
  color: $favColor;
  background-color: black;
}

#infoBtn:hover {
  border: 1px solid $favColor;
  color: black;
  background-color: $favColor;
}

// Footer 
nav.navbar.navbar-expand-md.fixed-bottom {
  background-color: #222222;
  color: $favColor;
}
// Home Page
.aboutMe {
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.title {
  align-self: center;
  font-size: 4rem;
  border-bottom: 2px solid $favColor;
  color: $favColor;
  letter-spacing: 14px;
  font-weight: bold;
  padding-top: 11%;
}

.jobTitle {
  align-self: center;
  color: $favColor;
  margin-top: 1%;
  font-size: 2rem;
}

div.btnContainer {
  text-align: center;
}

#about {
  background-color: transparent;
  border: 3px solid $favColor;
  color: $favColor;
  font-weight: bold;
  margin: 1%;
  width: 120px;
}

#about:hover {
  background-color: black;
}

.btnContainer {
  display: flex;
  justify-content: center;
  margin-top: 1%;
}

// About Me
.mainContainer {
  display: flex;
  color: $favColor;
  text-align: center;
}

.mainTitle {
  font-size: 1.3rem;
}

.about {
  align-self: center;
  font-size: 4rem;
  border-bottom: 2px solid $favColor;
  color: $favColor;
  letter-spacing: 14px;
  font-weight: bold;
  padding-top: 8%;
}

div.react-card-front {
  margin-top: 2%;
  text-align: center;
  padding: 2% 10%;
}
div.react-card-back {
  margin-top: 2%;
  text-align: center;
  padding: 2% 10%;
}

.introduction {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 15px;
}

ul.introduction-back {
  text-align: left;
  list-style: none;
  padding: 5%
}

.splitMain {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $favColor;
  background-color: #e4e6e8;
  font-size: 1.2rem;
}

.profileImg {
  height: 100%;
  width: 100%;
}

#projectBtn {
  background-color: #48494B;
  border-radius: 45%;
  color: #fff;
  width: 200px;
}
#projectBtn:hover {
  background-color: #2B2D2F;
  box-shadow: 0px 15px 20px rgba(105, 105, 105, 0.4);
  transform: translateY(-3px);
  border-radius: 45%;
  color: #fff;
}

#aboutBtn {
  background-color: #48494B;
  border-radius: 45%;
  color: #fff;
  width: 120px;
  margin-top: 3%;
}

#aboutBtn:hover {
  background-color: #2B2D2F;
  box-shadow: 0px 15px 20px rgba(105, 105, 105, 0.4);
  transform: translateY(-3px);
  border-radius: 45%;
  color: #fff;
}

// NSS Journal Page
.head {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $favColor;
  text-align: center;
  padding: 0 10%;
}

.vertical-timeline.vertical-timeline-custom-line::before {
  background: $secondaryColor;
}

.vertical-container {
  border: 4px solid white;
  padding: 2vh;
  text-align: center;
}

h3.vertical-timeline-element-title {
  margin-bottom: 10px;
}

.nssJourneyContent {
  color: $favColor;
  margin-top: 2%;
  font-size: 1.1rem;
}

.journalImg:hover {
  transform: scale(1.1);
}

// Project Page
div.projectsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}

div.card {
  flex-basis: 25%;
  min-height: 400px;
  border: none;
}

div.cardBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}

.marginLeft {
  max-width: 25%;
}

.readMore:hover {
  cursor: pointer;
}

.modalCard {
  color: #303030;
}

.hiddenTitle {
  display: none;
}

#cardDisplay {
  position: relative;
  padding: 0;
}

#projectImg {
  display: block;
  width: 100%;
  height: 100%;
}
.hiddenModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.hidden {
  font-size: 2rem;
  font-weight: bold;
}

.hiddenModal:hover {
  opacity: 1;
}

div.card.m-5.text-center.marginLeft {
  padding: 0;
}

.videoContainer {
  height: 300px;
}

// Modal Btn

#viewBtn {
  background-color: transparent;
  color: white;
}

#viewBtn:hover {
  background-color: $favColor;
}

#btnModal {
  background-color: transparent;
  width: 120px;
  color: $favColor;
}
#btnModal:hover {
  background-color: #2B2D2F;
  color: #ffffed;
}

// Technlogy Page
div.techCard {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 2%;
}

img#technologyImg {
  width: 150px;
  height: 150px;
  object-fit: scale-down;
}

div.technlogyContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2%;
}

.techCard.card {
  max-width: 200px;
  border: none;
  background-color: #f2f2f2;
}

.technology-name {
  color: $favColor;
}

// Contact Page
main.contactSplit {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  width: 100%;
}
section.contactContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

div.contactInfo {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
}

.contactImg {
  padding: 2%;
  height: 90vh;
  object-fit: fill;
}

.picture {
  width: 50%;
}

.img1 {
  width: 40%;
}

.img2 {
  width: 60%;
  margin: 0;
}

i.fab {
  font-size: 5rem;
  color: $favColor;
}

i.far {
  font-size: 5rem;
  color: $favColor;
}

i.fab:hover {
  color: #777777;
}


i.far:hover {
  color: #777777;
}

p.icon {
  text-align: center;
}

div.picture {
  align-self: flex-end;
}

#school {
  font-size: 2rem;
}

.journalImg {
  width: 400px;
}

@media screen and (max-width: 1100px) {
  .text-center.card-body {
    max-width: 50%;
  }
  div.mainContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;
  }
  div.splitMain {
    width: 100%;
  }
  section.contactContainer {
    width: 100%;
  }
  main.contactSplit {
    justify-content: center;
  }
  div.picture {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .aboutMe {
    margin-top: 3%;
  }
  .title {
    letter-spacing:1px;
    padding-top: 25%;
    font-size: 2.5rem;
  }
  .jobTitle {
    font-size: 1.5rem;
  }
  .about {
    letter-spacing:1px;
    padding-top: 25%;
    font-size: 2.5rem;
  }
  div.react-card-flip {
    padding: 5%;
  }
  p.introduction {
    align-self: stretch;
    align-items: center;
  }
  div.react-card-front {
    text-align: center;
    padding: 0%;
  }
  div.react-card-back {
    text-align: center;
    padding-top: 2%;
  }
  #projectImg {
    width: 100%;
    height: 100%;
  }
  div.card {
    flex-basis: 50%;
  }
  .splitMain {
    font-size: 0.9rem;
    width: 90%;
  }
  img.journalImg {
    width: 100%;
  }
  .text-center.card-body {
    max-width: 100%;
  }
  div.technlogyContainer {
    margin-top: 2%;
    padding-bottom: 2%;
  }
  div.btnContainer {
    display: flex;
    justify-content: space-evenly;
    padding-top: 5%;
  }
  #btn {
    margin: 0;
    width: 120px;
  }
  #btn:hover {
    margin: 0;
  }
}

@media screen and (max-width: 450px) {
  nav.navbar.navbar-expand-md.fixed-top {
    max-width: 100vw;
  }
  div.btnContainer {
    margin-top: 10%;
  }
  div.react-card-back {
    padding: 0;
    margin: 0;
  }
  div.react-card-front {
    padding: 0;
    margin: 0;
  }
  ul.introduction-back {
    padding: 0;
  }
  img.profileImg {
    width: 100%;
  }
  img.journalImg {
    width: 100%;
  }
  main.contactSplit {
    padding: 0 10%;
  }
  img.contactImg {
    width: 100%;
    padding: 0;
  }
  div.mainContainer {
    margin-top: 5%;
  }
  div.card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  #tech {
    font-size: 1.6rem;
  }
  div.card {
    height: 300px;
  }
  div.techCard.card {
    height: auto;
  }
  p.introduction {
    padding: 5%;
  }
  ul.introduction-back {
    padding: 5%;
  }
  #projectBtn {
    width: auto;
  }
  #projectBtn:hover {
    width: auto;
  }
}

@media screen and (max-width: 350px) {
  img.modalImg.card-img{
   object-fit: scale-down;
  }

  p.nssJourneyContent {
    font-size: small;
  }
}
